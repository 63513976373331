.sidebar {
  position: absolute;
  top: 65px;
  height: calc(100vh - 65px);
  width: 215px;
  text-align: center;
  
  #sidebar-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;

    .sidebar-top {
      box-sizing: border-box;
      height: 67%;
      display: flex;
      flex-direction: column;
      padding-top: 30px;

      #link-container {
        height: 150px;
        font-size: 17px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        align-items: center;
  
        .link {
          box-sizing: border-box;
          padding: 7px 10px;
          transition: all .2s;
          border: 1px solid rgba(210,210,210,0.5);
          font-size: 14px;
          background-color: white;
          width: 90%;

          &:hover {
            transform: scale(1.11);
            cursor: pointer;
          }
        }
  
      }
    }
    
    .sidebar-bottom {
      padding: 10px;
      height: 33%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .sidebar-logos-bottom {
        width: 152px;
        margin: 0 auto;
        
        #sidebar-bottom-acis {
          margin: 0 auto;
          #acis-cont {
            #acis {
              max-width: 150px;
              border: 1px solid rgb(161,5,5);
              border-radius: 5px;
            }
          }
        }
      }
      
      .sidebar-text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 120px;
        margin-bottom: 5px;
        padding: 4px 10px;
        font-size: 10px;
        background-color: #eee;
        border-radius: 5px;
      }
    }
  }
}