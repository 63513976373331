.header {
  display: flex;
  height: 65px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  .header-center {
    width: calc(100vw - 220px);
    box-sizing: border-box;
    position: relative;

    #title-container {
      box-sizing: border-box;
      width: fit-content;
      height: 45px;
      padding: 11px 20px;
      margin: 0 auto;
      background-color: #eee;
      border-radius: 0px 0px 12px 12px;

      #site-title {
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  #nrcc-logo-cont {
    width: 220px;
    height: 65px;

    #nrcc-logo {
      max-height: 65px;
      overflow: hidden;

      #nrcc {
        max-width: 383px;
        transform: translate(-23%, -214px);
      }
    }
  }
}