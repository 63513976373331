#nav {
  grid-column: 1/25;
  grid-row: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  pointer-events: all;

  &>* {
    flex-grow: 1;
  }

  #title {
    flex-grow: 0;
  }
  
  .MuiInputLabel-root {
    white-space: nowrap;
  }
}