#legend-container {
  grid-row: 2/18;
  grid-column: 22/25;
  justify-self: end;
  position: relative;
  right: 10px;
  
  #color-container {
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    border: 1px solid #505050;

    div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      div {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: white;
        text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
        border: 1px solid gray;
      }
    }
  }
}