.App {
  width: 100vw;
  height: 100vh;
  min-width: 1000px;
  min-height: 650px;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  position: relative;
  background-color: rgba(76,192,236, 0.7);
  // background-color: rgb(250,250,250);

  .card {
    padding: 12px;
    background-color: rgba(255,255,255,0.70);
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.20);
    border-radius: 12px;
    backdrop-filter: blur(20px);
    transition: all 0.3s;
    z-index: 2;

    &:hover {
      background-color: rgba(255,255,255, 1);
      box-shadow: 3px 3px 12px 0 rgba(0,0,0,0.10);
    }
  }

  #moon {
    position: absolute;
    top: 5px;
    left: 5px;
    box-sizing: border-box;
    width: 200%;
    height: 200%;
    background-color: #043575;
    border-radius: 50%;
  }

  #overlay-grid {
    position: absolute;
    top: 65px;
    left: 215px;
    box-sizing: border-box;
    width: calc(100% - 215px);
    height: calc(100% - 65px);
    padding: 12px;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 60px repeat(17, 1fr);
    overflow: hidden;
    pointer-events: none;
    border: 1px solid black;
    z-index: 10;
  }
}

@media only screen and (max-width: 1178px) {
  .MuiSelect-root {
    max-width: 350px;
  }
}

@media only screen and (max-width: 1100px) {
  .MuiSelect-root {
    max-width: 280px;
  }
}

