.close-modal {
  position: absolute;
  top: 7px;
  right: 7px;

  &:hover {
    cursor: pointer;
  }
}

#custom-modal-title {
  font-size: 32px;
  margin-top: 0;
}

#custom-modal-description {
  #equation {
    margin: 0 auto;
    width: fit-content;
  }

  .list-title {
    font-weight: bold;
    margin-top: 20px;
  }

  .modal-footnote {
    margin-top: 20px;
    font-size: 15px;
    text-align: center;
  }

  #download-all {
    background-color: #f50057;
    color: white;
    margin-top: 4px;
    height: 40px;
    font-size: 13px;
    margin: 0 calc(50% - 84px);
  }

  .figure1 {
    width: 560px;
    position: relative;
    left: -13px;
  }

  .figCaption {
    font-style: italic;
    font-size: 14px;
    width: 491px;
    margin: -20px auto 25px auto;
    z-index: 1;
    position: relative;
  }

  ol {
    margin-top: 15px;
    margin-left: 20px;

    li {
      margin: 10px 5px;
    }
  }

  p {
    margin: 10px 0px
  }

  iframe {
    margin-bottom: 20px;
  }
}