#chart-container {
  position: relative;
  pointer-events: all;
  grid-column: 1/25;
  grid-row: 2/19;
  padding: 0;
  overflow: hidden;

  .close-btn {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 2;
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: rgb(220,220,220);
    }
  }

  .highcharts-scatter-series {
    transform: translate(-11px, 85px);

    & text {
      font-size: 15px !important;
    }
  }

  .highcharts-bar-series {
    transform: translate(-11px, 30px);

    & text {
      font-size: 15px !important;
    }
  }
}