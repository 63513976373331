.marker {
  --highlight-color: red;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 11px;
  height: 11px;
  transform: translate(0, -100%) rotate(45deg);
  border-radius: 8px 8px 2px 8px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;

  &:hover {
    transform: translate(0, -100%) rotate(45deg) scale(1.5);
  }

  &.current {
    transform: translate(0, -100%) rotate(45deg) scale(2.0);
    border-color: var(--highlight-color);

    .marker-dot {
      border: none;
      height: 0;
      width: 0;
    }
  }

  .marker-dot {
    width: 3px;
    height: 3px;
    border-radius: 100%;
    border: 1px solid black;
    box-shadow: 0px 0px 0px 8px rgb(255, 255, 255);
    transition: all 0.3s;
  }
}