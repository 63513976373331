#map-cont {
  width: calc(100vw - 215px);
  height: calc(100vh - 65px);
  min-height: 450px;
  min-width: 785px;
  position: relative;
  left: 215px;

  .map-nav {
    position: absolute;
    bottom: 35px;
    left: 10px;
  }

  .popup-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding: 0px;
    max-width: 400px;

    .popup-title {
      font-weight: 600;
    }

    .popup-num {
      display: flex;
      justify-content: space-between;
      padding-left: 12px;
      padding-right: 20px;
    }

    .popup-footnote {
      font-size: 10px;
      font-style: italic;
    }

    hr {
      width: 220px;
      margin: 0px;
    }

    .popup-options {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }

    .popup-splitline {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .with-spacers {
      margin-bottom: 12px;
      gap: 15px;
    }
  }
}
